import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ========================= */
  sectionTitle: {
    marginBottom: 15,
    maxWidth: 280,

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 675,
    },
  },
  sectionDescription: {
    textAlign: 'center',
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
    },
  },

  /**
   * || Hero
   * ================= */
  hero: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    height: 450,
    top: -96, // Account for the header bar height
    marginBottom: 100,
    paddingTop: 96,
    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,

    backgroundColor: '#F0F4F8',

    [theme.breakpoints.up('desktopXL')]: {
      height: 650,
    },
  },
  /**
   * Contains the .heroTitle and .heroDescription
   */
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    maxWidth: 650,
    marginTop: 80,
    width: '90%',

    textAlign: 'center',

    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 120,
    },
  },
  heroTitle: {
    color: 'black',
    whiteSpace: 'pre-line',
  },
  heroDescription: {
    maxWidth: 550,
    margin: '20px 0px',

    color: 'rgba(115, 115, 115, 1)',
    whiteSpace: 'pre-line',
  },

  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '30%',
  },
  errorMsg: {
    fontSize: 40,
    color: '#f88994',
    [theme.breakpoints.up('desktop')]: {
      fontSize: 50,
    },
  },
}))

export default function Error404() {
  const classes = useStyles()
  const browser = typeof window !== 'undefined' && window
  return (
    browser && (
      <>
        <Helmet>
          <title>404 Page not found</title>
          <meta
            name='description'
            content='We were unable to locate the page you were looking for'
          />
        </Helmet>
        <main>
          <section className={classes.hero}>
            <div className={classes.heroContent}>
              <Typography variant='h1' className={classes.heroTitle}>
                Page not found
              </Typography>

              <Typography variant='body1' className={classes.heroDescription}>
                We were unable to locate the page you were looking for
              </Typography>
            </div>
            <div className={classes.errorContainer}>
              <Typography variant='body2' className={classes.errorMsg}>
                404
              </Typography>
            </div>
          </section>
        </main>
      </>
    )
  )
}
